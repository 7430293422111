import "./App.css";
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Home from "./components/Home";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Support from "./components/Support";
import WebFont from "webfontloader";
WebFont.load({google: {families: ["Roboto:300,400,500,600,800"]}});

if (typeof window !== 'undefined') {
  mixpanel.init('', {
    debug: process.env.NODE_ENV === 'development',
  });
}

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/support" element={<Support />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

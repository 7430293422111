

function Terms() {
  return(
  <div style={{ padding: '20px', lineHeight: '1.6' }}>
    <h1>Terms and Conditions</h1>
    <p>Welcome to LiveNote!</p>

    <p>
      These terms and conditions outline the rules and regulations for the use of LiveNote, developed by Nibble Audio, Inc.
    </p>

    <h2>Acceptance of Terms</h2>
    <p>
      By accessing and using LiveNote, you accept and agree to be bound by these Terms and Conditions. 
      If you do not agree with any part of these terms, you must not use our app.
    </p>

    <h2>Use of the App</h2>
    <p>
      LiveNote is intended for personal use only. You agree to use the app in compliance with all applicable laws and regulations.
    </p>

    <h2>Intellectual Property</h2>
    <p>
      The app and its original content, features, and functionality are and will remain the exclusive property of Nibble Audio, Inc.
    </p>

    <h2>Privacy</h2>
    <p>
      Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your information.
    </p>

    <h2>Modifications to the App</h2>
    <p>
      Nibble Audio, Inc. reserves the right to modify, suspend, or discontinue, temporarily or permanently, the app or any service to which it connects, with or without notice and without liability to you.
    </p>

    <h2>Termination</h2>
    <p>
      We may terminate or suspend your access to LiveNote immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
    </p>

    <h2>Limitation of Liability</h2>
    <p>
      In no event shall Nibble Audio, Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the app; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.
    </p>

    <h2>Changes to Terms</h2>
    <p>
      Nibble Audio, Inc. reserves the right, at its sole discretion, to modify or replace these Terms at any time. 
      If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect.
      What constitutes a material change will be determined at our sole discretion.
    </p>

    <h2>Contact Us</h2>
    <p>
      If you have any questions about these Terms, please contact us at support@nibbleaudio.com.
    </p>
  </div>
  );
}

export default Terms;


function Privacy() {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6' }}>
    <h1>Privacy Policy</h1>
    <p>Last updated: [Date]</p>

    <p>
      Nibble Audio, Inc. ("us", "we", or "our") operates the LiveNote app (the "Service").
      This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
    </p>

    <h2>Information Collection and Use</h2>
    <p>
      We do not collect or store any personal data except for the notes you create and save in LiveNote.
      Your notes are stored securely using Firebase.
    </p>

    <h2>Firebase</h2>
    <p>
      We use Firebase, a cloud service provider, to store your notes.
      Firebase is provided by Google Inc. and is compliant with industry standards for data security and privacy.
      For more information on Firebase's privacy practices, please visit <a href="https://firebase.google.com/support/privacy">Firebase Privacy</a>.
    </p>

    <h2>Analytics</h2>
    <p>
      We use third-party services such as Firebase Analytics and Mixpanel to monitor and analyze the use of our Service.
    </p>

    <h3>Firebase Analytics</h3>
    <p>
      Firebase Analytics is an analytics service provided by Google Inc.
      For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy">Google Privacy</a>.
    </p>

    <h3>Mixpanel</h3>
    <p>
      Mixpanel is provided by Mixpanel Inc.
      You can opt-out of Mixpanel's automatic retention of data collected through your browser by clicking on the link: <a href="https://mixpanel.com/optout/">Mixpanel Opt-Out</a>.
      For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <a href="https://mixpanel.com/terms/">Mixpanel Terms</a>.
    </p>

    <h2>Security of Data</h2>
    <p>
      The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
      While we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security.
    </p>

    <h2>Service Providers</h2>
    <p>
      We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.
    </p>

    <h2>Links to Other Sites</h2>
    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third-party link, you will be directed to that third party's site.
    </p>
    </div>
  );
}

export default Privacy;

import { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    if (window.location.pathname !== "/home") {
      //window.location.href = "https://apps.apple.com/us/app/betweet/id6444735198";
    }
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        justifyContent: "flex-start"
      }}
      className="font-face-gm"
    >
       <a
        target="_blank"
        rel="noreferrer"
        href={"https://apps.apple.com/us/app/betweet/id6444735198"}
      >
        <img
          src="/icon.png"
          alt="LiveNote logo"
          style={{
            height: "auto",
            width: 100,
            marginVertical: 50,
            cursor: "pointer",
            borderRadius: 20
          }}
        />
      </a>
      <h1 style={{textAlign: "left", paddingLeft: "4%", paddingTop: "12%", marginBottom: 20}}>LiveNote</h1>
      <h2 style={{textAlign: "left", paddingLeft: "4%", width: "40%", marginBottom: 20}}>Lock Screen reminders</h2>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://apps.apple.com/us/app/betweet/id6444735198"
      >
        <img
          src="/appstore_badge.svg"
          alt="Download on the App Store"
          style={{
            height: "auto",
            width: 180,
            marginTop: 10,
            cursor: "pointer",
            position: 'absolute',
            bottom: "10%",
            left: "4%",
          }}
        />
      </a>
      <div style={footerStyle}><Link style={{paddingRight: 20, textAlign: "left",}} to="/terms">Terms</Link><Link style={{paddingRight: 20, textAlign: "left",}} to="/privacy">Privacy</Link></div>
    </div>
  );
}

const footerStyle = {
  textAlign: "center",
  justifyContent: 'center',
  alignItems: 'center',
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "20px",
  width: "100%"
};

export default Home;


function Support() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
      }}
      className="font-face-gm"
    >
      <p>We do not offer support...</p>
      <p>just kidding email us at team@onlywidget.com</p>
    </div>
  );
}

export default Support;
